import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const hash = "/#";

const ListVenezuela = () => {
  const data = useStaticQuery(graphql`
    {
      allGoogleSpreadsheetDiccionarioIlogicoRespuestasDeFormulario1(
    filter: {pa_s: {in: "Venezuela"}}
    sort: {order: ASC, fields: palabra}
  ){
        nodes {
          usos__Ejemplos
          significado
          palabra
          pa_s
          enviadoPor
          id
          marcaTemporal
        }
      }
    }
  `)
  return (
    <>
      
      <div className="word-container">
        {data.allGoogleSpreadsheetDiccionarioIlogicoRespuestasDeFormulario1.nodes.map((single, index) => (
          <div className="word" key={single.id} id={single.palabra.toLowerCase()}>            
            <h2>
              <span>
                {single.pa_s === "Chile" ? "🇨🇱" : "🇨🇴 "}
              </span>              
              {single.palabra}              
              <Link to={`${hash}${single.palabra.toLowerCase()}`}>🔗</Link>
            </h2>
            <p className="meaning">{single.significado}</p>
            <p className="uses"><strong>Usos: </strong> {single.usos__Ejemplos}</p>
            <p className="author">Enviado por: {single.enviadoPor}</p>
          </div>
        ))}
      </div>
    </>
  )
}

export default ListVenezuela